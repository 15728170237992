<template>
  <div class="d-flex align-items-center flex-column mt-5">
    <v-stage ref="stage" :config="configKonva">
      <v-layer ref="layer">
        <!--<v-rect
          :config="{
            x: 0,
            y: 0,
            width: 2010,
            height: 1020,
            fill: 'red'
          }"
        />-->
        <v-rect
          v-for="item in $options.list"
          :key="item.id"
          :config="{
            x: item.x,
            y: item.y,
            width: 15,
            height: 15,
            id: item.id,
            fill: colors[item.rarity],
            stroke: '#f8f8f8',
            strokeWidth: 1
          }"
          @mouseover="overBlock"
        ></v-rect>
      </v-layer>
    </v-stage>
    <div class="coords">Row: {{ coords.row }} Cell: {{ coords.cell }}</div>
  </div>
</template>

<script>
import Packer from "../packer";
import blocksMeta from "../../data.json";
export default {
  data() {
    return {
      list: [],
      dragItemId: null,
      configKonva: {
        width: 2010,
        height: 1020
      },
      colors: {
        common: "#8eff97",
        uncommon: "#a7f1fe",
        rare: "#1edaff",
        epic: "#e569fb",
        legendary: "#fad649",
        satoshi: "#fd8918",
        unknown: "#dddddd"
      },
      coords: { row: 1, cell: 1 }
    };
  },
  created() {
    /*var packer = new Packer(2010, 1020);
    var blocks = [];
    let id = 0;
    for (let row = 1; row < 69; row++) {
      for (let cell = 1; cell < 135; cell++) {
        const pos = this.getXY({ row, cell });
        blocks.push({
          w: 15,
          h: 15,
          x: pos.x,
          y: pos.y,
          rarity: packer.getRarity({ row, cell }),
          area: 15 * 15,
          id: id
        });
        id++;
      }
    }*/
    this.$options.list = blocksMeta;
  },
  mounted() {
    //this.list = blocks;
  },
  methods: {
    overBlock(e) {
      //console.log("Over block: ", e.target.attrs);
      const newCords = this.getRowCell(e.target.index);
      if (newCords.row !== this.coords.row || newCords.cell !== this.coords.cell) {
        this.coords = { ...newCords };
      }
    },
    getRowCell(id) {
      const row = id == 0 ? 1 : Math.ceil(id / 134);
      const reminder = id % 134;
      const cell = reminder === 0 ? 134 : reminder;
      return { row, cell };
    },
    getXY({ row, cell }) {
      return { x: (cell - 1) * 15, y: (row - 1) * 15 };
    },
    colorByRarity(rarity) {
      return this.colors[rarity];
    }
  }
};
</script>

<style></style>
