<template>
  <div class="d-flex align-items-center flex-column">
    <h3>Random blocks for Tier {{ tier }}</h3>
    <div class="d-flex mb-3">
      <div class="form-check mr-3" v-for="index in 6" :key="index">
        <input class="form-check-input" type="radio" :value="index" v-model="tier" checked />
        <label class="form-check-label" for="exampleRadios1"> Tier {{ index }} </label>
      </div>
    </div>

    <v-stage ref="stage" :config="configKonva">
      <v-layer :ref="'layer' + index" v-for="index in 6" :key="index" :visible="index === tier">
        <!--<v-rect
          :config="{
            x: 0,
            y: 0,
            width: 2010,
            height: 1020,
            fill: 'red'
          }"
        />-->
        <v-rect
          v-for="item in tierList(index)"
          :key="item.id"
          :config="{
            x: item.x,
            y: item.y,
            width: 15,
            height: 15,
            id: item.id,
            fill: colors[item.rarity],
            stroke: '#f8f8f8',
            strokeWidth: 1
          }"
        ></v-rect>
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
import blocksMeta from "../../data-tiers.json";
function* filter(array, condition, maxSize) {
  if (!maxSize || maxSize > array.length) {
    maxSize = array.length;
  }
  let count = 0;
  let i = 0;
  while (count < maxSize && i < array.length) {
    if (condition(array[i])) {
      yield array[i];
      count++;
    }
    i++;
  }
}
function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}
export default {
  data() {
    return {
      configKonva: {
        width: 2010,
        height: 1020
      },
      colors: {
        common: "#8eff97",
        uncommon: "#a7f1fe",
        rare: "#1edaff",
        epic: "#e569fb",
        legendary: "#fad649",
        satoshi: "#fd8918",
        unknown: "#dddddd"
      },
      tier: 1
    };
  },
  computed: {
    list() {
      return blocksMeta.filter(row => row.tier == this.tier);
    }
  },
  created() {
    //const common = shuffle(blocksMeta.filter(row => row.rarity == "common"));
    //const uncommon = shuffle(blocksMeta.filter(row => row.rarity == "uncommon"));
    //this.$options.list = blocksMeta.filter(row => row.tier == this.tier);
  },
  mounted() {
    console.log(this.$refs.layer1);
  },
  methods: {
    tierList(tier) {
      return blocksMeta.filter(row => row.tier == tier);
    },
    overBlock(e) {
      //console.log("Over block: ", e.target.attrs);
      const newCords = this.getRowCell(e.target.index);
      if (newCords.row !== this.coords.row || newCords.cell !== this.coords.cell) {
        this.coords = { ...newCords };
      }
    },
    getRowCell(id) {
      const row = id == 0 ? 1 : Math.ceil(id / 134);
      const reminder = id % 134;
      const cell = reminder === 0 ? 134 : reminder;
      return { row, cell };
    },
    getXY({ row, cell }) {
      return { x: (cell - 1) * 15, y: (row - 1) * 15 };
    },
    colorByRarity(rarity) {
      return this.colors[rarity];
    }
  }
};
</script>

<style></style>
