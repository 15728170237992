<template>
  <div style="display:flex; width:100%;justify-content:center;">
    <!--<div class="board" ref="board">
      <div class="row" v-for="index of 100" :key="index">
        <div class="cell" :class="[colors[Math.floor(Math.random() * 4)], isMyPixel([index, ii]) ? 'my' : '']" v-for="ii of 100" :key="ii"></div>
      </div>
      <a href="https://ethereum.org" class="image" target="_blank" :style="posInPixels"><img :src="require('../assets/pxArt.png')" title="Some message or link on it"/></a>
    </div>-->
    <!--<div id="macy-container" ref="board">
      <div class="grid-item" v-for="(row, index) of builtBlocks" :key="index" :style="row"></div>
    </div>-->
    <canvas ref="canvas" id="canvas" width="2010" height="1020"></canvas>
    <div style="margin-left:20px;font-size:20px;font-weight:bold;">{{ ratio }}%</div>
    <div class="colors" style="display:none;">
      <h4>States of the pixel</h4>
      <div class="line"><span class="gray box"></span> Pixel not claimed</div>
      <div class="line"><span class="blue box"></span> Pixel have been claimed (with border my pixels)</div>
      <div class="line"><span class="red box"></span> Pixel available for sale</div>
      <div class="line"><span class="purple box"></span> Pixel have an active bid</div>
      <div class="line">
        <span class="box"><img :src="require('../assets/image.png')" style="width:10px;height:10px;"/></span> Image with message?
      </div>
    </div>
  </div>
</template>

<script>
import Masonry from "masonry-layout";
import Packery from "packery";
import Draggabilly from "draggabilly";
import GrowingPacker from "../growing.packer";
import Packer from "../packer";
import sort from "../sort";

export default {
  data() {
    return {
      colors: ["red", "blue", "purple", "gray", "green", "yellow", "brown"],
      bgColors: ["#d0e979", "#e8a8c1", "#e9e494", "#d6adad", "#adc9d6", "#b8a7ce", "#9ab7e2"],
      image: [40, 40],
      myPixels: [
        [11, 5],
        [11, 6],
        [11, 7],
        [11, 8],
        [12, 5],
        [12, 6],
        [12, 7]
      ],
      /*blocks: {
        4500: [1, 1],
        300: [2, 2],
        50: [4, 4],
        40: [5, 5],
        30: [10, 3],
        10: [10, 10],
        5: [20, 6]
      },*/
      pcolors: {
        pastel: ["#FFF7A5", "#FFA5E0", "#A5B3FF", "#BFFFA5", "#FFCBA5"],
        basic: ["silver", "gray", "red", "maroon", "yellow", "olive", "lime", "green", "aqua", "teal", "blue", "navy", "fuchsia", "purple"],
        gray: ["#111", "#222", "#333", "#444", "#555", "#666", "#777", "#888", "#999", "#AAA", "#BBB", "#CCC", "#DDD", "#EEE"],
        vintage: ["#EFD279", "#95CBE9", "#024769", "#AFD775", "#2C5700", "#DE9D7F", "#7F9DDE", "#00572C", "#75D7AF", "#694702", "#E9CB95", "#79D2EF"],
        solarized: ["#b58900", "#cb4b16", "#dc322f", "#d33682", "#6c71c4", "#268bd2", "#2aa198", "#859900"],
        none: ["transparent"]
      },
      complex: [
        { w: 10, h: 10, num: 4500 },
        { w: 20, h: 20, num: 300 },
        { w: 40, h: 40, num: 50 },
        { w: 50, h: 50, num: 40 },
        { w: 100, h: 30, num: 30 },
        { w: 100, h: 100, num: 10 },
        { w: 200, h: 60, num: 5 }
      ],
      shapes: [],
      builtBlocks: [],
      msnry: null,
      packer: null,
      draw: null,
      ratio: null,
      interval: null
    };
  },
  computed: {
    posInPixels() {
      const x = (this.image[0] + 2) * 10;
      const y = (this.image[1] + 2) * 10;
      return { top: x + "px", left: y + "px" };
    },
    demoBlocks() {
      var expanded = [];
      for (let i = 0; i < this.complex.length; i++) {
        for (let j = 0; j < this.complex[i].num; j++) expanded.push({ w: this.complex[i].w, h: this.complex[i].h, area: this.complex[i].w * this.complex[i].h });
      }
      return expanded;
    },
    allBlocks() {
      var expanded = [];
      for (let i = 0; i < 1000; i++) {
        expanded.push({ w: 15, h: 15, area: 15 * 15 });
      }
      return expanded;
    }
  },
  created() {
    //this.getBlocks();
  },
  mounted() {
    this.draw = this.$refs.canvas.getContext("2d");
    //this.interval = setInterval(this.run, 200);
    this.run();
  },
  methods: {
    run() {
      this.packer = new Packer(2010, 1020);
      this.reset(2010, 1020);
      console.time("start");
      const blocks = [...this.allBlocks];
      //sort.now(blocks, "random");
      this.packer.fit(blocks);
      this.blocks(blocks);
      //this.boundary(this.packer.root);
      this.report(blocks, this.packer.root.w, this.packer.root.h);
    },
    reset(width, height) {
      this.$refs.canvas.width = width + 1; // add 1 because we draw boundaries offset by 0.5 in order to pixel align and get crisp boundaries
      this.$refs.canvas.height = height + 1; // (ditto)
      this.draw.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
    },
    blocks(blocks) {
      var n, block;
      for (n = 0; n < blocks.length; n++) {
        block = blocks[n];
        if (block.fit) this.rect(block.fit.x, block.fit.y, block.w, block.h, this.color(n));
      }
    },
    color(n) {
      var cols = this.pcolors["pastel"];
      return cols[n % cols.length];
    },
    boundary(node) {
      if (node) {
        this.stroke(node.x, node.y, node.w, node.h);
        this.boundary(node.down);
        this.boundary(node.right);
      }
    },
    rect(x, y, w, h, color) {
      this.draw.fillStyle = color;
      this.draw.fillRect(x + 0.5, y + 0.5, w, h);
    },
    stroke(x, y, w, h) {
      this.draw.strokeRect(x + 0.5, y + 0.5, w, h);
    },
    report(blocks, w, h) {
      var fit = 0,
        nofit = [],
        block,
        n,
        len = blocks.length;
      for (n = 0; n < len; n++) {
        block = blocks[n];
        if (block.fit) fit = fit + block.area;
        else nofit.push("" + block.w + "x" + block.h);
      }
      this.ratio = Math.round((100 * fit) / (w * h));
      this.notFit = nofit.length;
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    isMyPixel(cord) {
      for (let index = 0; index < this.myPixels.length; index++) {
        const pixel = this.myPixels[index];
        if (this.arraysEqual(pixel, cord)) {
          return true;
        }
      }
      return false;
    },
    arraysEqual(a1, a2) {
      /* WARNING: arrays must not contain {objects} or behavior may be undefined */
      return JSON.stringify(a1) == JSON.stringify(a2);
    },
    blockSize(arr, color) {
      return { width: arr[0] * 10 + "px", height: arr[1] * 10 + "px", backgroundColor: color };
    },
    getBlocks() {
      const keys = Object.keys(this.blocks);
      const ret = [];
      for (let index = 0; index < keys.length; index++) {
        const count = keys[index];
        for (let i = 0; i < count; i++) {
          ret.push(this.blockSize(this.blocks[keys[index]], this.bgColors[index]));
        }
      }
      this.builtBlocks = this.shuffle(ret);
    }
  }
};
</script>

<style>
#canvas {
  background-color: #fbfbfb;
}
#macy-container {
  width: 1000px;
  max-width: 1000px;
}
.grid-item {
  float: left;
}
.grid-item:hover {
  opacity: 0.5;
}
.board {
  display: flex;
  flex-direction: column;
  position: relative;
}
.image {
  display: block;
  width: 120px;
  height: 120px;
  position: absolute;
  background: white;
}
.image img {
  width: 120px;
  height: 120px;
}
.row {
  height: 12px;
  display: flex;
}
.cell {
  height: 10px;
  width: 10px;
  border: 1px solid;
}
.cell:hover {
  border: 1px solid black;
}
.cell.my {
  border: 1px solid black;
  background-color: #adc9d6;
}
.red {
  background-color: #d6adad;
  border-color: #d6adad;
}
.blue {
  background-color: #adc9d6;
  border-color: #adc9d6;
}
.purple {
  background-color: #b8a7ce;
  border-color: #b8a7ce;
}
.gray {
  background-color: #dddddd;
  border-color: #dddddd;
}
.colors {
  margin-left: 100px;
  display: flex;
  flex-direction: column;
}
.box {
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 5px;
}
.line {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}
</style>
