<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import PixelBoard from "./components/PixelBoard.vue";
import NewBoard from "./components/NewBoard.vue";
import Tiers from "./components/Tiers.vue";
import PixieTiers from "./components/PixieTiers.vue";
export default {
  name: "App",
  components: {
    NewBoard,
    Tiers,
    PixieTiers
    //PixelBoard
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
