var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center flex-column"},[_c('h3',[_vm._v("Random blocks for Tier "+_vm._s(_vm.tier))]),_c('div',{staticClass:"d-flex mb-3"},_vm._l((6),function(index){return _c('div',{key:index,staticClass:"form-check mr-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tier),expression:"tier"}],staticClass:"form-check-input",attrs:{"type":"radio","checked":""},domProps:{"value":index,"checked":_vm._q(_vm.tier,index)},on:{"change":function($event){_vm.tier=index}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"exampleRadios1"}},[_vm._v(" Tier "+_vm._s(index)+" ")])])}),0),_c('v-stage',{ref:"stage",attrs:{"config":_vm.configKonva}},_vm._l((6),function(index){return _c('v-layer',{key:index,ref:'layer' + index,refInFor:true,attrs:{"visible":index === _vm.tier}},_vm._l((_vm.tierList(index)),function(item){return _c('v-rect',{key:item.id,attrs:{"config":{
          x: item.x,
          y: item.y,
          width: 15,
          height: 15,
          id: item.id,
          fill: _vm.colors[item.rarity],
          stroke: '#f8f8f8',
          strokeWidth: 1
        }}})}),1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }