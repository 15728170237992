/*
 * Starter Router
 */

// Vue and Vue Router
import Vue from "vue";
import Router from "vue-router";

// Register Vue Router
Vue.use(Router);

//Auth pages
const PixieTiers = () => import("./components/PixieTiers.vue");
const Prof = () => import("./components/Prof.vue");
const PixelBoard = () => import("./components/PixelBoard.vue");

// Router Configuration
const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  linkExactActiveClass: "",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "Tiers",
      component: PixieTiers
    },
    {
      path: "/prof",
      name: "Prof",
      component: Prof
    }
  ]
});
export default router;
