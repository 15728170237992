/******************************************************************************

This is a very simple binary tree based bin packing algorithm that is initialized
with a fixed width and height and will fit each block into the first node where
it fits and then split that node into 2 parts (down and right) to track the
remaining whitespace.

Best results occur when the input blocks are sorted by height, or even better
when sorted by max(width,height).

Inputs:
------

  w:       width of target rectangle
  h:      height of target rectangle
  blocks: array of any objects that have .w and .h attributes

Outputs:
-------

  marks each block that fits with a .fit attribute pointing to a
  node with .x and .y coordinates

Example:
-------

  var blocks = [
    { w: 100, h: 100 },
    { w: 100, h: 100 },
    { w:  80, h:  80 },
    { w:  80, h:  80 },
    etc
    etc
  ];

  var packer = new Packer(500, 500);
  packer.fit(blocks);

  for(var n = 0 ; n < blocks.length ; n++) {
    var block = blocks[n];
    if (block.fit) {
      Draw(block.fit.x, block.fit.y, block.w, block.h);
    }
  }


******************************************************************************/

const Packer = function(w, h) {
  this.init(w, h);
};

Packer.prototype = {
  init: function(w, h) {
    this.root = { x: 0, y: 0, w: w, h: h };
  },

  getXY({ row, cell }) {
    return { x: (cell - 1) * 15, y: (row - 1) * 15 };
  },

  fit: function(blocks) {
    var n, node, block;
    for (n = 0; n < blocks.length; n++) {
      block = blocks[n];
      if ((node = this.findNode(this.root, block.w, block.h))) block.fit = this.splitNode(node, block.w, block.h);
    }
  },

  findNode: function(root, w, h) {
    if (root.used) return this.findNode(root.right, w, h) || this.findNode(root.down, w, h);
    else if (w <= root.w && h <= root.h) return root;
    else return null;
  },

  splitNode: function(node, w, h) {
    node.rarity = this.getRarity(node.x, node.y);
    node.used = true;
    node.down = { x: node.x, y: node.y + h, w: node.w, h: node.h - h };
    node.right = { x: node.x + w, y: node.y, w: node.w - w, h: h };
    return node;
  },

  getRarity({ row, cell }) {
    //console.log("Get rarity of: ", x, y);
    var keys = Object.keys(this.rarityBonds);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      for (let index = 0; index < this.rarityBonds[key].length; index++) {
        const el = this.rarityBonds[key][index];
        if (cell >= el[0] && cell <= el[1] && row >= el[2] && row <= el[3]) {
          //console.log("Found rarity: ", key);
          return key;
        }
      }
    }
    return "unknown";
  },

  rarityBonds: {
    satoshi: [[58, 77, 25, 44]],
    legendary: [
      [47, 88, 22, 24],
      [47, 57, 25, 47],
      [58, 88, 45, 47],
      [78, 88, 25, 45]
    ],
    epic: [
      [42, 93, 17, 21],
      [42, 46, 21, 52],
      [47, 93, 48, 52],
      [89, 93, 22, 48]
    ],
    rare: [
      [27, 41, 17, 52],
      [94, 108, 17, 52]
    ],
    uncommon: [
      [18, 117, 9, 16],
      [18, 26, 17, 60],
      [27, 117, 53, 60],
      [109, 117, 17, 53]
    ],
    common: [
      [1, 134, 1, 8], //top
      [1, 17, 8, 68], //left
      [17, 134, 61, 68], //bottom
      [118, 134, 8, 61] //right
    ]
  }
};
export default Packer;
