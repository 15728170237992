<template>
  <div class="d-flex align-items-center flex-column">
    <router-link to="/prof">Prof</router-link>
    <h3>Random blocks for Tier {{ tier }}</h3>
    <div class="d-flex mb-3">
      <div class="form-check mr-3" v-for="index in 6" :key="index">
        <input class="form-check-input" type="radio" :value="index" v-model="tier" checked />
        <label class="form-check-label" for="exampleRadios1"> Tier {{ index }} </label>
      </div>
    </div>
    <canvas ref="canvas" id="canvas" width="2010" height="1020"></canvas>
  </div>
</template>

<script>
import blocksMeta from "../../data/data-tiers.json";
function* filter(array, condition, maxSize) {
  if (!maxSize || maxSize > array.length) {
    maxSize = array.length;
  }
  let count = 0;
  let i = 0;
  while (count < maxSize && i < array.length) {
    if (condition(array[i])) {
      yield array[i];
      count++;
    }
    i++;
  }
}
function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}
import { Application, Graphics } from "pixi.js";
export default {
  data() {
    return {
      configKonva: {
        width: 2010,
        height: 1020
      },
      colors: {
        common: "#8eff97",
        uncommon: "#a7f1fe",
        rare: "#1edaff",
        epic: "#e569fb",
        legendary: "#fad649",
        satoshi: "#fd8918",
        unknown: "#dddddd"
      },
      colorsHex: {
        common: 0x8eff97,
        uncommon: 0xa7f1fe,
        rare: 0x1edaff,
        epic: 0xe569fb,
        legendary: 0xfad649,
        satoshi: 0xfd8918,
        unknown: 0xdddddd
      },
      tier: 1,
      app: null,
      scene: null,
      tiers: []
    };
  },
  watch: {
    tier(val) {
      this.showTier(val);
    }
  },
  created() {},
  mounted() {
    // create an new instance of a pixi stage
    this.app = new Application({ view: this.$refs.canvas, backgroundColor: 0xffffff, width: 2010, height: 1020, autoStart: true });
    //this.app.stage.setInteractive(true);
    this.showTier(this.tier);
  },
  methods: {
    showTier(tier) {
      if (this.scene) this.scene.destroy();
      this.scene = new Graphics();
      this.app.stage.addChild(this.scene);
      const blocks = this.tierList(tier);
      this.scene.clear();
      console.log(blocks[blocks.length - 1]);
      for (let index = 0; index < blocks.length; index++) {
        const block = blocks[index];
        this.scene.beginFill(this.colorsHex[block.rarity]);
        this.scene.drawRect(block.x, block.y, 15, 15);
      }
    },
    tierList(tier) {
      return blocksMeta[tier].filter(row => row.tier == tier);
    },
    overBlock(e) {
      //console.log("Over block: ", e.target.attrs);
      const newCords = this.getRowCell(e.target.index);
      if (newCords.row !== this.coords.row || newCords.cell !== this.coords.cell) {
        this.coords = { ...newCords };
      }
    },
    getRowCell(id) {
      const row = id == 0 ? 1 : Math.ceil(id / 134);
      const reminder = id % 134;
      const cell = reminder === 0 ? 134 : reminder;
      return { row, cell };
    },
    getXY({ row, cell }) {
      return { x: (cell - 1) * 15, y: (row - 1) * 15 };
    },
    colorByRarity(rarity) {
      return this.colors[rarity];
    }
  }
};
</script>

<style></style>
