var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center flex-column mt-5"},[_c('v-stage',{ref:"stage",attrs:{"config":_vm.configKonva}},[_c('v-layer',{ref:"layer"},_vm._l((_vm.$options.list),function(item){return _c('v-rect',{key:item.id,attrs:{"config":{
          x: item.x,
          y: item.y,
          width: 15,
          height: 15,
          id: item.id,
          fill: _vm.colors[item.rarity],
          stroke: '#f8f8f8',
          strokeWidth: 1
        }},on:{"mouseover":_vm.overBlock}})}),1)],1),_c('div',{staticClass:"coords"},[_vm._v("Row: "+_vm._s(_vm.coords.row)+" Cell: "+_vm._s(_vm.coords.cell))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }